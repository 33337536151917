export enum ROUTES {
  ARTIFACT = '/admin/artefact',
  ARTISTS = '/admin/artists',
  CATEGORIES = '/admin/categories',
  CMS = '/admin/cms',
  CONTRIBUTE_ARTISTIC = '/admin/contribute-artistic',
  CONTRIBUTE_MONETARY = '/admin/contribute-monetary',
  CONTRIBUTE_DONATE = '/admin/contribute-donate',
  DASHBOARD = '/admin/dashboard',
  EDUCATION_CATEGORIES = '/admin/history-education',
  EXHIBITION = '/admin/exhibition',
  GALLERY = '/admin/gallery',
  PRIVATE_VIEW = '/admin/private-view',
  HEADINGS = '/admin/headings',
  LOGOUT = '/auth/logout',
  LOGIN = '/auth/login',
  METADATA = '/admin/metadata',
  PROFILE = '/admin/profile',
  ROLES = '/admin/roles',
  MEMBER = '/admin/member',
  ACTIVITY = '/admin/activity',
  CHANGE_PASSWORD = '/admin/change-password',
  CONFIGURATION = '/admin/configuration',
  USERS = '/admin/user/list',
  EMAIL_SUBSCRIBER = '/admin/email-subscriber',
  CONTACT_US = '/admin/user/contact-us',
  KEYWORDS = '/admin/keywords'
}
