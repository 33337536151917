import npm from '../../package.json';

export const environment = {
  production: false,
  type: 'development',
  encryptedKey: process.env.ENCRYPTED_KEY,
  version: npm.version,
  authApi: 'https://sikh-api.focalat.com/auth',
  preferredCountries: ['us', 'in'],
  awsUrl: 'https://shm-stg.s3.ap-south-1.amazonaws.com/dshm-dev/',
  logo: '/assets/images/logo.svg',
  title: 'Digital Sikh History Museum Admin Console',
  email: 'sikhhistorymuseum@gmail.com',
  webDomainUrl: 'https://dev-dshm.focalat.com',
  privateUrl: 'https://privateviews.dev-dshm.focalat.com/view/'
};
