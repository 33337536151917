export const STORAGE = {
  LANGUAGE_KEY: 'lk',
  REDIRECT_TO: 'cd_rdto',
  CURRENT_LANGUAGE_STATE_KEY: 'clsk',
  LOGIN_TOKEN: 'lt',
  USER_ROLES: 'ur',
  USER_DATA: 'ud',
  MY_PERMISSIONS: 'mp',
  FULL_NAME: 'fn',
  EXHIBITION_ID: 'ei',
  ARTIFACT_LIST_TYPE: 'LIST_TYPE',
  ARTIFACT_SORT_TYPE: 'SORT_TYPE',
  ARTIFACT_SORT_BY: 'SORT_BY'
};
