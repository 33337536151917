import { environment } from '@environment/environment';

export const API = {
  NOTES: `${environment.authApi}/api/notes`,
  ARTIFACT: `${environment.authApi}/api/artefact`,
  ARTIST: `${environment.authApi}/api/artist`,
  CATEGORY: `${environment.authApi}/api/category`,
  CMS: `${environment.authApi}/api/cms`,
  DONATED_FUNDS: `${environment.authApi}/api/payment`,
  DASHBOARD: `${environment.authApi}/api/dashboard`,
  EBOOK: `${environment.authApi}/api/ebook`,
  EXHIBITION: `${environment.authApi}/api/exhibition`,
  FORGOT_PASSWORD: `${environment.authApi}/api/auth/forgot-password`,
  GALLERY: `${environment.authApi}/api/gallery`,
  KEYWORD: `${environment.authApi}/api/keywords`,
  HEADING: `${environment.authApi}/api/heading`,
  LOGIN: `${environment.authApi}/api/auth/login`,
  METADATA: `${environment.authApi}/api/metadata`,
  NATIONALITIES: `${environment.authApi}/api/constant`,
  PERMISSIONS: `${environment.authApi}/api/permission`,
  MY_PERMISSIONS: `${environment.authApi}/api/permission/signed-users`,
  RESET_PASSWORD: `${environment.authApi}/api/auth/reset-password`,
  CHANGE_PASSWORD: `${environment.authApi}/api/admin/change-password`,
  ROLES: `${environment.authApi}/api/role`,
  MEMBERS: `${environment.authApi}/api/admin`,
  PROFILE: `${environment.authApi}/api/admin/me`,
  UPDATE_PROFILE: `${environment.authApi}/api/admin`,
  ACTIVITY: `${environment.authApi}/api/activity-logger`,
  UPLOAD_URL: `${environment.authApi}/api/artefact/upload-media`,
  BLOG: `${environment.authApi}/api/blog`,
  LEARN: `${environment.authApi}/api/learn`,
  HISTORY_EDUCATION_SUBCATEGORY: `${environment.authApi}/api/blog/sub-category`,
  BOOK_LIBRARY: `${environment.authApi}/api/book`,
  GENERATE_URL: `${environment.authApi}/api/generate-url`,
  CONFIGURATION: `${environment.authApi}/api/config`,
  USERS: `${environment.authApi}/api/customer`,
  CONTACT_US: `${environment.authApi}/api/contact-us`,
  ARTISTIC_CONTRIBUTION: `${environment.authApi}/api/contribution`,
  FEEDBACK: `${environment.authApi}/api/feedback`,
  EMAIL_SUBSCRIBER: `${environment.authApi}/api/web-email-subscription`,
  DONATE_CONTRIBUTION: `${environment.authApi}/api/contribution/view-donation`,
  PRIVATE_VIEW: `${environment.authApi}/api/private-view`
};

export const CACHE_API = [
  API.NATIONALITIES,
  API.PERMISSIONS,
  API.ARTIST + '/list',
  API.CATEGORY + '/list',
  API.HEADING + '/list',
  API.GALLERY + '/list'
];
